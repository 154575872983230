import { DateTime } from 'luxon';
import { ReactNode } from 'react';
import { FieldPathValue, Path } from 'react-hook-form';

export type Children = { children: ReactNode };

export enum ImageType {
  thumbnail = 'thumbnail',
  optimized = 'optimized',
}

export enum Categories {
  APPLIANCE = 'APPLIANCE',
  CLEANING = 'CLEANING',
  DOOR = 'DOOR',
  ELECTRICAL = 'ELECTRICAL',
  EXTERIOR = 'EXTERIOR',
  GENERAL = 'GENERAL',
  HVAC = 'HVAC',
  KEYS = 'KEYS',
  PLUMBING = 'PLUMBING',
  SAFETY = 'SAFETY',
  SECURITY = 'SECURITY',
  WINDOW = 'WINDOW',
}

export enum RequestStatus {
  SUBMITTED = 'SUBMITTED',
  CONFIRMED = 'CONFIRMED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  STARTED = 'STARTED',
  MISSED = 'MISSED',
}

export const RequestDisplayLabel: Record<string, string> = {
  SUBMITTED: 'SUBMITTED',
  CONFIRMED: 'CONFIRMED',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
  ASSIGNED: 'ASSIGNED',
  STARTED: 'STARTED',
  OVERDUE: 'OVERDUE',
  DELAYED: 'DELAYED',
  MISSED: 'MISSED',
  UNSCHEDULED: 'SUBMITTED',
  SCHEDULED: 'SCHEDULED',
};

export const Provinces = {
  AB: 'AB',
  BC: 'BC',
  MB: 'MB',
  NT: 'NT',
  QC: 'QC',
  SK: 'SK',
  YT: 'YT',
  NU: 'NU',
  ON: 'ON',
  NB: 'NB',
  NL: 'NL',
  NS: 'NS',
  PE: 'PE',
} as const;

export enum AmenityCategories {
  RECREATION = 'recreation',
  SECURITY = 'security',
  STORAGE = 'storage',
  VEHICLE = 'vehicle',
  PETS = 'pets',
  GREEN_SPACE = 'green space',
  WASTE = 'waste',
  ACCESSIBILITY = 'accessibility',
  SERVICES = 'services',
}

export const DocumentTypes = {
  GOVERNMENT_IDENTITY: 'Government-issued identification',
  EMPLOYEMENT_LETTER: 'Employment Letter',
  PAYSTUB: 'Paystub',
  BANK_STATEMENT: 'Bank Statement',
  REFERENCES: 'References',
  OTHER: 'Other',
  PROOF_OF_ENROLMENT: 'Proof of enrolment',
  IMMIGRATION_DOC: 'Immigration Document',
} as const;

export const DefaultDocumentTypeOptions = Object.values(DocumentTypes).map((type) => ({
  label: type,
  value: type,
}));

export type Image = {
  key?: string;
};

export type Merge<T> = { [K in keyof T]: T[K] };

export type DateLike = string | Date | DateTime | number;

export type Option<TData = unknown> = {
  id: string;
  text: string;
  subText?: string;
  disabled?: boolean;
  disabledMessage?: string;
  data?: TData;
};

export const OccupancyType = {
  RENTAL: 'rental',
  OWNER: 'owner',
  COMMON: 'common',
  COMMERCIAL: 'commercial',
  COMMERCIALOWNER: 'commercialOwner',
} as const;

export type OccupancyType = typeof OccupancyType;

export type NonNullableField<
  Map extends Record<string, unknown>,
  Breadcrumbs extends Path<Map>,
> = NonNullable<FieldPathValue<Map, Breadcrumbs>>;
