import {
  LinkProps,
  ThemeProvider as MaterialThemeProvider,
  Theme,
  useMediaQuery,
} from '@mui/material';
import { alpha, createTheme } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';
import { forwardRef, useMemo } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { Children, colours } from 'system';
import { registerPdfFonts } from 'system/pdfUtil';
import { ThemeContext } from './useTheme';

LicenseInfo.setLicenseKey(
  '059e99c4ed6695e6cfefeded11141c17Tz03NTk3OCxFPTE3MjgwNjI3OTMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);
declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/material/styles/createPalette' {
  export interface Palette {
    tertiary: Palette['primary'];
    primaryBackground: Palette['primary'];
    inputBackground: Palette['common']['black'];
    units: {
      leased: PaletteColor;
      commercial: PaletteColor;
      owner: PaletteColor;
      common: PaletteColor;
      empty: PaletteColor;
    };
  }
  export interface PaletteOptions {
    tertiary?: PaletteOptions['primary'];
    primaryBackground?: PaletteOptions['primary'];
    inputBackground: Palette['common']['black'];
    units: {
      leased: PaletteColorOptions;
      commercial: PaletteColorOptions;
      owner: PaletteColorOptions;
      common: PaletteColorOptions;
      empty: PaletteColorOptions;
    };
  }
}

const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

export default function ThemeProvider({ children }: Children) {
  // Register fonts in PDFs via theme provider, could do more styling tweaks that matches MUI in here
  registerPdfFonts();

  const darkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const themeContext = { darkMode };

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: darkMode ? 'dark' : 'light',
          text: {
            secondary: colours.nobel,
            primary: darkMode ? colours.white : colours.paynesGrey,
          },
          primary: {
            light: colours.lightSlateBlue,
            main: darkMode ? colours.lightSlateBlue : colours.purpleHeart,
          },
          secondary: {
            main: colours.shamrock,
          },
          tertiary: {
            main: darkMode ? colours.whiteSmoke : colours.black,
          },
          success: {
            light: colours.waterLeaf,
            main: colours.shamrock,
            dark: colours.elfGreen,
          },
          warning: {
            light: colours.moccasin,
            main: colours.orange,
            dark: colours.darkGoldenrod,
          },
          error: {
            main: colours.mahogany,
          },
          background: {
            default: darkMode ? colours.black : colours.whiteSmoke,
            paper: darkMode ? colours.nero : colours.white,
          },
          primaryBackground: {
            main: darkMode ? colours.cherryPie : colours.magnolia,
          },
          divider: darkMode ? colours.blackMarlin : colours.silver,
          inputBackground: darkMode ? alpha('#FFFFFF', 0.09) : alpha('#000000', 0.06),
          units: {
            leased: {
              main: darkMode ? colours.lightSlateBlue : colours.purpleHeart,
              dark: colours.persianIndigo,
              contrastText: colours.whiteSmoke,
            },
            commercial: {
              light: colours.waterLeaf,
              main: colours.shamrock,
              dark: colours.elfGreen,
            },
            owner: {
              main: darkMode ? colours.darkGoldenrod : colours.orange,
              dark: colours.bakersChocolate,
            },
            common: {
              main: darkMode ? colours.cornflowerBlue : colours.lightSkyBlue,
              dark: colours.sanMarino,
            },
            empty: {
              main: darkMode ? colours.black : colours.whisper,
              dark: darkMode ? colours.blackMarlin : colours.nobel,
              contrastText: darkMode ? colours.whiteSmoke : colours.black,
            },
          },
        },
        components: {
          MuiButtonBase: {
            defaultProps: {
              LinkComponent: LinkBehavior,
            },
          },
          MuiCssBaseline: {
            styleOverrides: {
              html: { height: '100%' },
              body: { height: '100%' },
            },
          },
          MuiLink: {
            styleOverrides: {
              root: {
                '&:visited': {
                  color: darkMode ? colours.lightSlateBlue : colours.purpleHeart,
                },
              },
            },
            defaultProps: {
              component: LinkBehavior,
            } as LinkProps,
          },
        },
        typography: {
          fontFamily: 'Work Sans',
          ...Object.fromEntries(
            ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'subtitle1', 'subtitle2', 'overline'].map(
              (variant) => [variant, { fontFamily: 'Poppins' }]
            )
          ),
        },
      }),
    [darkMode]
  );

  return (
    <MaterialThemeProvider theme={theme}>
      <ThemeContext.Provider value={themeContext}>{children}</ThemeContext.Provider>
    </MaterialThemeProvider>
  );
}
