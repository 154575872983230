import {
  AccountFieldsFragment,
  AutopayEnrollmentFieldsFragment,
  ListAnnouncementInboxQuery,
  ProfileFieldsFragment,
  PropertyHeaderFieldsFragment,
  ResidencyFieldsFragment,
  Resident,
  ResidentFieldsFragment,
  TenantFieldsFragment,
  UnitHeaderFieldsFragment,
  useGetCampaignsQuery,
  useGetProfileQuery,
  useGetTenantQuery,
  useListAnnouncementInboxQuery,
  useListProfileRequestsQuery,
  useProfileWithResidentsQuery,
  useResidentsQuery,
} from 'api';
import { useMeta } from 'hooks';
import { usePaginateAllQuery } from 'hooks/usePaginateAllQuery';
import { compact, groupBy, map, uniq, uniqBy } from 'lodash';
import { ReactNode, createContext, useContext, useMemo } from 'react';
import { NonNullableField, ensureArray } from 'system';
import { useBooksActivated } from '../hooks/useBooksActivated';
import RequestContent from '../pages/maintenance/Request/RequestContent';

export type PropertyAsset = PropertyHeaderFieldsFragment & {
  accountId: string;
  residentId: string;
};
export type UnitAsset = UnitHeaderFieldsFragment & { accountId: string; residentId: string };
export type AutopayEnrollment = AutopayEnrollmentFieldsFragment & {
  accountId: string;
  payeeId: string;
};

export type ProfileResidency = ResidencyFieldsFragment & {
  residentId: string;
  sharerHeader?: { id: string; name?: string; email?: string };
};

export type ProfileContextType = {
  loading: boolean;
  profile?: ProfileFieldsFragment;
  tenant?: TenantFieldsFragment;
  hasMaintenance: boolean;
  hasAssets: boolean;
  hasRequests: boolean;
  hasCampaigns: boolean;
  hasBillingEnabled?: boolean;
  hasDocuments: boolean;
  hasResidencies: boolean;
  currentResidencies: ProfileResidency[];
  futureResidencies: ProfileResidency[];
  ownedUnits: UnitAsset[];
  ownedProperties: PropertyAsset[];
  accounts: AccountFieldsFragment[];
  autopayEnrollments: AutopayEnrollment[];
  fixedEnrollments: AutopayEnrollment[];
  residentIds: string[];
  residentAccountIds: { id: string; accountId: string }[];
  announcementInbox: NonNullableField<
    ListAnnouncementInboxQuery,
    'user.profile.listInboxMessages.items'
  >;
  hasAnnouncements: boolean;
};

const ProfileContext = createContext<ProfileContextType | undefined>(undefined);

export const useProfileContext = () => {
  const context = useContext(ProfileContext);
  if (context === undefined) {
    throw new Error('useProfileContext must be used within an ProfileProvider');
  }
  return context;
};

export const ProfileProvider = ({ children }: { children: ReactNode }) => {
  const { data: profileData, ...getProfileMeta } = useProfileWithResidentsQuery();
  const profile = useMemo(() => profileData?.user?.profile, [profileData?.user?.profile]);

  const { data: tenantData, ...getTenantMeta } = useGetTenantQuery({
    skip: !profile?.tenants?.[0],
    variables: { id: profile?.tenants?.[0].id ?? '' },
  });

  const tenantContext = useMemo(
    () => ({
      tenant: tenantData?.tenantById,
    }),
    [tenantData]
  );

  const { data: residentsData, ...getResidentsMeta } = useResidentsQuery({
    skip: !profile?.id,
  });

  const residentContext = useMemo(() => {
    const currentResidencies = ensureArray(residentsData?.user?.profile?.residents)
      .filter((r) => !r.sharerHeader)
      .reduce(
        (residencies: ProfileResidency[], r) => [
          ...residencies,
          ...ensureArray(r.currentResidencies).map((rs) => ({
            ...rs,
            residentId: r.id,
            ...(r?.sharerHeader ? { sharerHeader: r.sharerHeader } : {}),
          })),
        ],
        []
      );
    const futureResidencies = ensureArray(residentsData?.user?.profile?.residents)
      .filter((r) => !r.sharerHeader)
      .reduce(
        (residencies: ProfileResidency[], r) => [
          ...residencies,
          ...ensureArray(r.futureResidencies).map((rs) => ({ ...rs, residentId: r.id })),
        ],
        []
      );
    const residentIds = ensureArray(residentsData?.user?.profile?.residents).reduce(
      (ids: string[], r) => [...ids, r.id],
      []
    );
    const residentAccountIds = ensureArray(residentsData?.user?.profile?.residents).map((r) => ({
      id: r.id,
      accountId: r.accountId,
    }));

    const { fixed: fixedEnrollments = [], autopay: autopayEnrollments = [] } = groupBy(
      ensureArray(residentsData?.user?.profile?.residents).flatMap((r) => [
        ...ensureArray(r.autopayEnrollments).map((e) => ({
          ...e,
          accountId: r.accountId,
          payeeId: r.id,
        })),
      ]),
      (x) => (x.clearables?.[0] ? 'fixed' : 'autopay')
    );

    const ownedAssets = {
      ownedUnits: ensureArray(residentsData?.user?.profile?.residents).reduce(
        (units, r) => [
          ...units,
          ...ensureArray(r.unitHeaders)
            .filter((h) => r.unitIds?.includes(h.id))
            .map((u) => ({
              ...u,
              accountId: r.accountId,
              residentId: r.id,
            })),
        ],
        [] as UnitAsset[]
      ),
      ownedProperties: ensureArray(residentsData?.user?.profile?.residents).reduce(
        (properties, r) => [
          ...properties,
          ...ensureArray(r.propertyHeaders)
            .filter((h) => r.propertyIds?.includes(h.id))
            .map((p) => ({
              ...p,
              accountId: r.accountId,
              residentId: r.id,
            })),
        ],
        [] as PropertyAsset[]
      ),
    };

    return {
      residentAccountIds,
      residentIds,
      hasMaintenance:
        ownedAssets.ownedUnits?.some(
          (p) => !p.sharedMeta || p.sharedMeta?.permissions?.includes('MAINTENANCE')
        ) ||
        ownedAssets.ownedProperties?.some(
          (p) => !p.sharedMeta || p.sharedMeta?.permissions?.includes('MAINTENANCE')
        ),
      hasAssets: ensureArray(residentsData?.user?.profile?.residents).some(
        (r) => r.unitIds?.[0] || r.propertyIds?.[0]
      ),
      ...ownedAssets,
      accounts: compact(
        uniqBy(
          ensureArray(residentsData?.user?.profile?.residents).reduce(
            (accountHeaders, r) => [...accountHeaders, r.accountHeader],
            [] as ResidentFieldsFragment['accountHeader'][]
          ),
          'id'
        )
      ),
      hasDocuments: ensureArray(residentsData?.user?.profile?.residents).some(
        (r) => r.documents?.length
      ),
      autopayEnrollments,
      fixedEnrollments,
      currentResidencies,
      futureResidencies,
      hasResidencies: Boolean(
        currentResidencies?.filter((l) => !residentIds.includes(l.unitOwnerId ?? '')).length ||
          futureResidencies?.filter((l) => !residentIds.includes(l.unitOwnerId ?? '')).length
      ),
    };
  }, [residentsData]);

  const { data: campaignsData, ...getCampaignsMeta } = useGetCampaignsQuery({
    skip: !profile?.id,
  });

  const campaignsContext = useMemo(
    () => ({
      hasCampaigns: Boolean(campaignsData?.campaigns?.length),
    }),
    [campaignsData]
  );

  const { anyActivated: booksActivated } = useBooksActivated(
    uniq(map(profile?.residents, 'accountId'))
  );
  const billingContext = useMemo(
    () => ({
      hasBillingEnabled: booksActivated,
    }),
    [booksActivated]
  );

  const [requests, getRequestsMeta] = usePaginateAllQuery(useListProfileRequestsQuery, {
    getItems: (d) => d.user?.profile?.listRequests.items,
    getNextToken: (d) => d?.user?.profile?.listRequests?.nextToken,
  });

  const requestsContext = useMemo(
    () => ({
      hasRequests: Boolean(requests?.length),
    }),
    [requests]
  );

  const [announcementInbox, announcementsInboxMeta] = usePaginateAllQuery(
    useListAnnouncementInboxQuery,
    {
      getNextToken: (d) => d?.user?.profile?.listInboxMessages?.nextToken,
      getItems: (d) => d?.user?.profile?.listInboxMessages?.items,
    }
  );

  const announcementInboxContext = useMemo(
    () => ({
      announcementInbox: ensureArray(announcementInbox),
      hasAnnouncements: ensureArray(announcementInbox).length > 0,
    }),
    [announcementInbox]
  );

  const { loading } = useMeta(
    getProfileMeta,
    getTenantMeta,
    getResidentsMeta,
    getCampaignsMeta,
    getRequestsMeta,
    announcementsInboxMeta
  );
  const value = useMemo<ProfileContextType>(
    () => ({
      loading,
      profile,
      ...tenantContext,
      ...residentContext,
      ...campaignsContext,
      ...billingContext,
      ...requestsContext,
      ...announcementInboxContext,
    }),
    [
      loading,
      profile,
      tenantContext,
      residentContext,
      campaignsContext,
      billingContext,
      requestsContext,
      announcementInboxContext,
    ]
  );

  return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>;
};
